.merchantHome,
.prodacts {
  background-color: #f1f1f1;
}
.merchantHome h3,
.prodacts h3 {
  font-size: 1.625rem;
  margin: 1.5rem 0;
  color: var(--blueColor);
}
.homeContent {
  display: flex;
}
.mainSideBar {
  background-color: #fff;
  width: 217px;
}
.mainContent {
  width: calc(100% - 217px);
  min-height: 100vh;
  padding: 2rem;
  padding-bottom: 3.4rem;
}
.sidebarToggleBtn {
  display: none;
}

.topHeading {
  margin: 1em 0 2em;
}

.sectionHeading {
  /* margin: 1em 0;
  margin-bottom: 2em; */
  color: black;
  display: flex;
  align-items: center;
}

.sectionHeading h1 {
  margin-bottom: 0 !important;
}
.sectionHeading strong {
  font-size: 1.3em;
  color: black;
  margin-right: 0.5em;
}

.sectionHeading strong + span {
  font-size: 0.7em;
}
.sectionHeading button {
  width: 136px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  color: #ffffff;
  /* background: #0bbf73; */
  margin-left: 1em;
  font-size: 1.1em;
}

.sectionSubHeading h2 {
  margin: 0.5em;
  font-size: 1.5em !important;
  color: black;
}

/* Products */
.topHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prodacts h3 {
  margin-bottom: 0.5rem;
}
.prodacts p {
  margin-bottom: 1.5rem;
  color: var(--blueColor);
  height: 50px;
}
.importModal input {
  background-color: #fff;
}
.currentBtn,
.uploadBtn,
.importBtn,
.invitationBtn,
.inviteBtn,
.submitBtn {
  border-radius: 5px;
  display: inline-block;
  height: 50px;
  font-size: 1.25rem;
  background-color: var(--blueColor);
  color: #fff;
  border: none;
  margin-top: 0.5rem;
}

.importBtn:hover,
.addReviewBtn:hover,
.submitBtn:hover,
.invitationBtn:hover,
.inviteBtn:hover {
  background-color: var(--blueColor);
  color: #fff;
}
.currentBtn,
.uploadBtn {
  background-color: #fff;
  color: var(--blueColor) !important;
}
.currentBtn {
  border: 1px solid black !important;
  background-color: #fff !important;
  width: 100%;
}

.reviewsCard {
  background-color: #fff;
  color: var(--blueColor);
  width: 100%;
  height: 530px;
  padding: 2rem;
  margin: 1rem 0;
  border-radius: 10px;
  position: relative;
}
.reviewsCard h3 {
  text-align: center;
}
.addReviewBtn {
  border-radius: 5px;
  width: 176px;
  height: 50px;
  font-size: 1.25rem;
  background-color: var(--blueColor);
  color: #fff;
  border: none;
  position: absolute;
  top: 45px;
  right: 30px;
}

.reviewsContent {
  margin-top: 3rem;
}

.allReviews {
  display: flex;
  justify-content: space-between;
}
.editBtn {
  /* .deleteBtn { */
  border-radius: 5px;
  font-size: 1.25rem;
  background-color: var(--blueColor);
  color: #fff;
  border: none;
  height: 30px;
  line-height: 1;
  margin: 0 0.3rem;
}
.editBtn:hover,
.deleteBtn:hover {
  background-color: var(--blueColor);
  color: #fff;
}
.deleteBtn {
  background-color: #ff0000;
}
.deleteBtn:hover {
  background-color: #ff0000;
}

/* team */
.teamTable {
  width: 100%;
  height: 70vh;
  margin: 1rem 0;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  overflow-x: auto;
}
.teamTable table {
  width: 100%;
  background-color: #fff;
  text-align: center;
  color: #57586e;
}
.teamTable table th,
.teamTable table td {
  width: auto;
  height: 70px;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 0.625em;
}
.teamTable table td:nth-child(3) {
  text-transform: capitalize;
}
.inviteBtn {
  width: 210px;
}
.invitationBtn {
  width: 86px;
  margin-top: 0;
}
.deleteItem {
  text-align: left;
  margin-right: 100%;
  display: inline-block;
  margin-top: 0;
}
.deleteItem:hover {
  cursor: pointer;
}
.upgradeBtn {
  border-radius: 5px;
  display: inline-block;
  height: 50px;
  font-size: 1.25rem;
  background-color: var(--blueColor);
  color: #fff;
  border: none;
  margin-top: 0.5rem;
  background-color: green;
  color: white;
}

.upgradeBtn:hover,
.upgradeBtn:focus {
  background-color: green !important;
  color: white !important;
}

.twitterBtn {
  background: transparent;
  outline: none;
  border: 1px solid gainsboro;
  padding: 10px;
  width: 175px;
  height: 40px;
  border-radius: 4px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.twitterBtn img {
  height: 20px;
  margin-left: 5px;
}

/* bestProduct */
.bestProduct {
  /* height: 495px; */
  border-radius: 10px;
  background: #fff;
  padding: 2em;
  color: black;
}
.bestProduct h3 {
  color: black;
  font-family: Inder;
  text-align: center;
  margin-top: 0;
}
.bestProductItems {
  margin: 1em 0;
  cursor: pointer;
}
.modalContainer button,
.advertisingTable table th button,
.bestProductItems button {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 1.25rem;
  background-color: black;
  border: none;
  margin-top: 0.5rem;
  color: white;
}
.modalContainer button:hover,
.bestProductItems button:hover {
  background-color: black;
  color: #fff;
}

.modalContainer button:active,
.advertisingTable table th button:active,
.bestProductItems button:active {
  background-color: black;
  color: #fff;
}

.bestProductItems p {
  text-align: left;
}
.bestProductItems img {
  width: 207px;
  height: 314px;
  border-radius: 10px;
  object-fit: contain;
}
/* .headerButtons {
  width: 28%;
} */
.headerButtons {
  margin-left: 1rem;
}
.advertisingTable {
  background-color: #fff;
  padding: 0em 3em;
  margin-top: 2em;
  border-radius: 5px;
  overflow-x: auto;
}
.advertisingTable table {
  width: 100%;
  color: black;
}
.advertisingTable table thead {
  border-bottom: 1px solid #57586e10;
}
.advertisingTable table th {
  width: 18%;
  padding: 0.5em 1em;
  border-left: 1px solid #57586e10;
}
.advertisingTable table th:first-child {
  width: 20%;
  border-left: none;
}
.advertisingTable table th strong {
  font-size: 2em;
  color: black;
}

.advertisingTable table td {
  padding: 1em;
  width: 15%;
  text-align: center;
  border-left: 1px solid #57586e10;
  border-bottom: 1px solid #57586e10;
}
.advertisingTable table td:first-child {
  width: 25%;
  text-align: left;
  border-left: none;
}
.advertisingTable table td svg {
  font-size: 2em;
  color: #27802b;
}
.modalContainer {
  /* max-width: 586px !important; */
  color: black;
  font-size: 1.2em;
}
.modalContainer button {
  width: 150px;
  margin: 1em auto;
}
.imgContainer img {
  width: 132px;
  height: 133px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgContainer img {
  max-width: 100%;
  height: auto;
}
.budgetBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
}

.activeBudget,
.budgetBtn span:last-child {
  width: 181px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59px;
  border-radius: 6px;
}

.modelTagContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
}

.modelTagContainer > div {
  width: 180px;
}

.activeBudget {
  border: 1px solid #b17a50;
  background: #f1f1f1;
  cursor: pointer;
}
.modalHeader {
  text-align: center;
}
.modalHeader svg {
  color: #27802b;
  font-size: 2em;
}
.modalHeader h6 {
  font-size: 1.3em;
  margin-bottom: 1em;
  color: #000;
}
.modalHeader strong {
  color: black;
  font-size: 3em;
  font-weight: 400;
}
.footerText {
  text-align: center;
  color: #57586e;
}
.aboutMessage {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.aboutMessage span {
  width: 40%;
  display: flex;
  align-items: center;
  font-size: 2em;
  color: #27802b;
}
.saveList {
  display: flex;
  flex-direction: column;
  color: #000000;
  max-height: 500px;
  overflow: auto;
}
.saveList span {
  display: flex;
  justify-content: start;
  align-items: center;
  color: #000000;
  font-size: 1.2em;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.yourList {
  width: 210px !important;
  color: #000 !important;
  font-size: 1.3em !important;
  justify-content: center;
  height: 63px !important;
  border-radius: 12px !important;
  background: #f6f5f5 !important;
}

.sectionHeading + a,
.sectionHeading.subscribeInfo > a,
.topHeading.subscribeInfo a {
  display: inline-block;
}
.planText {
  margin-top: 1rem;
  text-align: center;
}

.boostBanner {
  background: rgb(177, 122, 80);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  height: 70px;
  padding: 0 1em;
}

.boostBanner p {
  font-size: 1.2em;
  color: white;
}

.boostResultContainer > div:first-child {
  margin-top: 3em;
}

.boostResultContainer p {
  margin-bottom: 0;
  width: 20%;
}

.boostResultContainer button {
  width: 20%;
}

.boostResultContainer > section {
  margin-top: 1em;
}

.boostResultContainer > div:first-child > h4 {
  font-size: 1.3em;
  width: 20%;
}

.storeSettingContainer {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  margin: 1rem 0;
}

@media only screen and (max-width: 992px) {
  /* .mainContent {
    width: 100%;
    height: 100%;
    padding: 1rem;
    padding-bottom: 3.4rem;
  } */
  .bestProductItems img {
    height: auto;
  }
  .homeContent {
    position: relative;
  }
  .sidebarToggleBtn {
    display: block;
    text-align: right;
    font-size: 2rem;
    color: var(--blueColor);
  }
  .mainSideBar,
  .sidebarActive {
    background-color: #fff;
    position: absolute;
    z-index: 9999;
    left: -100%;
    width: 100%;
    height: 100%;
    transition: 350ms all ease-in-out;
  }
  .sidebarActive {
    left: 0;
    right: 0;
  }
  .mainContent {
    width: 100%;
    height: 100%;
    padding: 1rem;
    padding-bottom: 3.4rem;
  }
  .topHeading {
    display: block;
  }
  .sectionHeading {
    margin-bottom: 1.5em;
  }
  .sectionHeading + a,
  .sectionHeading.subscribeInfo > a,
  .topHeading.subscribeInfo a {
    display: none;
  }
  .bestProduct h3 {
    font-size: 14px;
  }
  .bestProductItems button {
    height: 40px;
    font-size: 1rem;
  }
  .headerButtons > a {
    display: none;
  }
  .invitationBtn {
    width: auto;
    height: 40px;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  /* bestProduct */
  .bestProduct {
    height: auto;
    border-radius: 10px;
    background: #fff;
    padding: 2em 0.5em;
    color: black;
  }
  /* .homeContent {
    position: relative;
  }
  .sidebarToggleBtn {
    display: block;
    text-align: right;
    font-size: 2rem;
    color: var(--blueColor);
  }
  .mainSideBar,
  .sidebarActive {
    background-color: #fff;
    position: absolute;
    z-index: 9999;
    left: -100%;
    width: 100%;
    height: 100%;
    transition: 350ms all ease-in-out;
  }
  .sidebarActive {
    left: 0;
    right: 0;
  }
  .mainContent {
    width: 100%;
    height: 100%;
    padding: 1rem;
    padding-bottom: 3.4rem;
  }
  .topHeading {
    display: block;
  } */
  .importBtn {
    margin-bottom: 1rem;
  }

  .teamTable {
    font-size: 0.7rem !important;
  }
  .invitationBtn {
    width: auto;
    height: 30px;
    font-size: 0.875rem;
    display: block;
    margin: 0.625em auto !important;
  }
}

@media only screen and (max-width: 576px) {
  .headerButtons {
    justify-content: center !important;
  }
  .headerButtons button {
    width: 100% !important;
    height: 40px;
    margin: 1.5em 0.5em 0 !important;
    font-size: 1rem;
  }
  .headerButtons > button {
    width: 100%;
  }
  .inviteBtn {
    width: auto;
    height: 35px;
    font-size: 0.875rem;
  }
  .planText {
    font-size: 0.875rem;
  }
  .tabHeading {
    font-size: 1.25rem;
  }
}
